import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

const BobGalleryPage = () => {
  return (
    <Layout>
      <h1 className="mobile-header lg:desktop-header">Bob's Art Gallery</h1>
      <div className="flex flex-col gap-4 mt-4">
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1287.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1289.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1300.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1296.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_6983.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_7688.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_8102.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1347.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1352.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1344.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_6644.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1305.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1314.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1337.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_6641.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1302.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/bob-landscape.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_6774.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/coco.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_6853.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/IMG_1339.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden">
          <div className="flex gap-4">
            <StaticImage
              height={585}
              src="../images/bob-gallery/01.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/02.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/03.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/04.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/05.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/06.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/07.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
            <StaticImage
              height={585}
              src="../images/bob-gallery/08.png"
              alt="Bob's art"
              placeholder="tracedSVG"
              className="flex-shrink-0"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BobGalleryPage
